import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { DisciplineDto, Page } from '../../../dtos';

export class DisciplinesResource {
    private readonly _api = inject(HttpClient);

    public getDisciplines(): Observable<Page<DisciplineDto>> {
        return this._api.get<Page<DisciplineDto>>('team/v1/disciplines');
    }

    public getDiscipline(disciplineId: number): Observable<DisciplineDto> {
        return this._api.get<DisciplineDto>(`team/v1/disciplines/${disciplineId}`);
    }

    public createDiscipline(discipline: DisciplineDto): Observable<DisciplineDto> {
        return this._api.post<DisciplineDto>('team/v1/disciplines', discipline);
    }

    public deleteDiscipline(disciplineId: number): Observable<DisciplineDto> {
        return this._api.delete<DisciplineDto>(`team/v1/disciplines/${disciplineId}`);
    }

    public updateDiscipline(discipline: DisciplineDto): Observable<DisciplineDto> {
        return this._api.put<DisciplineDto>(`team/v1/disciplines/${discipline.disciplineId}`, discipline);
    }
}
