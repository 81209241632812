import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { LocationToggleAccess, LoginDto, Page, UserDto } from '../../../dtos';

export class UsersResource {
    private readonly _api = inject(HttpClient);

    public getAllUsers(page: number, size: number): Observable<Page<UserDto>> {
        const params = new HttpParams().set('page', page).set('size', size);
        return this._api.get<Page<UserDto>>(`user/v1/users`, { params });
    }

    public createUser(userDto: UserDto): Observable<UserDto> {
        if (userDto.sendNotification) {
            const params = new HttpParams().set('sendNotification', userDto.sendNotification);
            return this._api.post<UserDto>(`user/v1/users`, userDto, { params });
        }
        return this._api.post<UserDto>(`user/v1/users`, userDto);
    }

    public getUser(userId: number, params?: { infoFull?: boolean }): Observable<UserDto> {
        return this._api.get<UserDto>(`user/v1/users/${userId}`, { params: { ...params } });
    }

    public updateUser(userDto: UserDto): Observable<UserDto> {
        return this._api.put<UserDto>(`user/v1/users/${userDto.userId}`, userDto);
    }

    public deleteUser(userId: number): Observable<void> {
        return this._api.delete<void>(`user/v1/users/${userId}`);
    }

    public toggleUserActive(userId: number): Observable<UserDto> {
        return this._api.put<UserDto>(`user/v1/users/${userId}/active`, null);
    }

    public toggleLocation(data: LocationToggleAccess): Observable<void> {
        return this._api.put<void>(`user/v1/users/${data.userId}/location/${data.locationId}`, null);
    }

    public changeRole(userDto: UserDto): Observable<UserDto> {
        return this._api.put<UserDto>(`user/v1/users/${userDto.userId}/role`, userDto);
    }

    public resetPassword(userDto: UserDto): Observable<UserDto> {
        if (userDto.sendNotification) {
            const params = new HttpParams().set('sendNotification', userDto.sendNotification);
            return this._api.put<UserDto>(`user/v1/users/${userDto.userId}/reset-password`, userDto, { params });
        }
        return this._api.put<UserDto>(`user/v1/users/${userDto.userId}/reset-password`, userDto);
    }

    public recordAuditLinkedTeamMember(userId: number): Observable<any> {
        return this._api.put<void>(`user/v1/users/audit-linked-team-member/${userId}`, null);
    }

    public changePassword(loginDto: LoginDto): Observable<UserDto> {
        return this._api.put<UserDto>(`user/v1/users/change-password`, loginDto);
    }
}
