import { Directive, ElementRef, Renderer2, OnInit, inject } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[pURandomColor]',
})
export class RandomColorDirective implements OnInit {
    private colors: string[] = [
        '#2E7A7C',
        '#92DCE5',
        '#A749AA',
        '#FC7D27',
        '#2490C6',
        '#4E5166',
        '#C2CC62',
        '#678D58',
        '#4C1E4F',
        '#9B7EDE',
        '#991A6F',
        '#FFAD4A',
        '#D9C5B2',
        '#F2D249',
        '#F79D84',
        '#959DF9',
        '#6FCEAE',
        '#F94C4A',
        '#6268B2',
        '#8ACAED',
        '#5795AA',
        '#323C84',
        '#6F48BC',
        '#25C8C6',
        '#E76F51',
        '#D93A49',
    ];

    private el = inject(ElementRef);
    private renderer = inject(Renderer2);

    public ngOnInit(): void {
        const randomColor = this.getRandomColor();
        this.renderer.setStyle(this.el.nativeElement, 'color', randomColor);
    }

    private getRandomColor(): string {
        const randomIndex = Math.floor(Math.random() * this.colors.length);
        return this.colors[randomIndex];
    }
}
