import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { Direction, EmployeeDto, Page } from '../../../dtos';

export class EmployeeResource {
    private readonly _api = inject(HttpClient);

    public createEmployee(employeeDto: EmployeeDto): Observable<EmployeeDto> {
        return this._api.post<EmployeeDto>(`contact/v1/employees`, employeeDto);
    }

    public getEmployee(employeeId: number): Observable<EmployeeDto> {
        return this._api.get<EmployeeDto>(`contact/v1/employees/${employeeId}`);
    }

    public getEmployees(params?: {
        listAll?: boolean;
        page?: number;
        size?: number;
        sort?: string;
        direction?: Direction;
        searchText?: string;
    }): Observable<Page<EmployeeDto>> {
        return this._api.get<Page<EmployeeDto>>(`contact/v1/employees`, { params: { ...params } });
    }

    public updateEmployee(employeeId: number, employeeDto: EmployeeDto): Observable<EmployeeDto> {
        return this._api.put<EmployeeDto>(`contact/v1/employees/${employeeId}`, employeeDto);
    }

    public toggleEmployeeStatus(employeeId: number): Observable<EmployeeDto> {
        return this._api.put<EmployeeDto>(`contact/v1/employees/toggle-active/${employeeId}`, null);
    }

    public deleteEmployee(employeeId: number): Observable<void> {
        return this._api.delete<void>(`contact/v1/employees/${employeeId}`);
    }
}
