import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { CaseFileDto, CaseFileLocationDto, CaseFileNoteDto, CaseFileStarredDto, Page } from '../../../dtos';

export class CaseFileResource {
    private readonly _api = inject(HttpClient);

    public createCaseFile(caseFileDto: CaseFileDto): Observable<CaseFileDto> {
        return this._api.post<CaseFileDto>(`casefile/v1/case-files`, caseFileDto);
    }

    public getCaseFiles(params: { locationId: number; page?: number; size?: number }): Observable<Page<CaseFileDto>> {
        return this._api.get<Page<CaseFileDto>>(`casefile/v1/case-files`, { params: { ...params } });
    }

    public getStarredCaseFiles(params: { locationId: number; page?: number; size?: number }): Observable<Page<CaseFileDto>> {
        return this._api.get<Page<CaseFileDto>>(`casefile/v1/case-files/starred`, { params: { ...params } });
    }

    public getCaseFile(
        caseId: number,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.get<CaseFileDto>(`casefile/v1/case-files/${caseId}`, { params: { ...params } });
    }

    public updateCaseFile(
        caseId: number,
        caseFileDto: CaseFileDto,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.put<CaseFileDto>(`casefile/v1/case-files/${caseId}`, caseFileDto, { params: { ...params } });
    }

    public deleteCaseFile(caseId: number, params: { locationId: number }): Observable<void> {
        return this._api.delete<void>(`casefile/v1/case-files/${caseId}`, { params: { ...params } });
    }

    public toggleCaseFileStatus(caseId: number, params: { locationId: number }): Observable<CaseFileDto> {
        return this._api.put<CaseFileDto>(`casefile/v1/case-files/toggle-status/${caseId}`, null, { params: { ...params } });
    }

    public setStarredCaseFile(caseId: number, params: { locationId: number }): Observable<CaseFileStarredDto> {
        return this._api.put<CaseFileStarredDto>(`casefile/v1/case-files/starred/${caseId}`, null, { params: { ...params } });
    }

    public moveHomeBranch(
        caseId: number,
        homeBranchId: number,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileLocationDto> {
        return this._api.put<CaseFileLocationDto>(`casefile/v1/case-files/home-branch/${caseId}/${homeBranchId}`, null, {
            params: { ...params },
        });
    }

    public deleteCaseFileNote(noteId: number, params: { locationId: number }): Observable<void> {
        return this._api.delete<void>(`casefile/v1/case-files/note/${noteId}`, { params: { ...params } });
    }

    public updateCaseFileNote(
        noteId: number,
        caseFileNoteDto: CaseFileNoteDto,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileNoteDto> {
        return this._api.put<CaseFileNoteDto>(`casefile/v1/case-files/note/${noteId}`, caseFileNoteDto, { params: { ...params } });
    }

    public createCaseFileNote(
        caseId: number,
        caseFileNoteDto: CaseFileNoteDto,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.post<CaseFileDto>(`casefile/v1/case-files/note/${caseId}`, caseFileNoteDto, { params: { ...params } });
    }
}
