import { Pipe, PipeTransform } from '@angular/core';

import { RateTierDto } from '../dtos';

@Pipe({
    standalone: true,
    name: 'lastRateTierValue',
})
export class LastRateTierValue implements PipeTransform {
    public transform(rateTier: RateTierDto, currencySymbol: string): string {
        const emptyCharacter = '—';
        if (rateTier?.rateTierHistory) {
            const rate = this.getLastRateValue(rateTier);
            return rate ? `${currencySymbol}${rate.toFixed(2)}` : emptyCharacter;
        }
        return emptyCharacter;
    }

    private getLastRateValue(rateTier: RateTierDto) {
        const length = rateTier.rateTierHistory!.length;

        if (length > 0) {
            return rateTier.rateTierHistory![length - 1].rate;
        }

        return null;
    }
}
