import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { AUTH_MANAGER, AUTH_NAVIGATOR, AuthManager, AuthNavigator, ERROR_CODES } from '@portal/core/utils';

export const userAuthInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authManager: AuthManager = inject(AUTH_MANAGER);

    if (!authManager.hasToken()) {
        return next(req);
    }

    const authNavigator: AuthNavigator = inject(AUTH_NAVIGATOR);

    return next(
        req.clone({
            // headers: request.headers.append
            setHeaders: { Authorization: `Bearer ${authManager.getToken()}` },
        })
    ).pipe(catchError((err: unknown) => (err instanceof HttpErrorResponse ? handleHttpError(err) : throwError(() => err))));

    function handleHttpError(err: HttpErrorResponse): Observable<any> {
        if (err.status === ERROR_CODES.UNAUTHORIZED) {
            authManager.resetAuthorizationToken();
            void authNavigator.logoutNavigate();
        }
        if (err.status === ERROR_CODES.FORBIDDEN) {
            authManager.resetAuthorizationToken();
            void authNavigator.logoutNavigate();
        }
        // TODO split auth exception from others
        // if (err.status === ERROR_CODES.SERVER_ERROR) {
        //     authManager.resetAuthorizationToken();
        //     void authNavigator.logoutNavigate();
        // }
        return throwError(() => err);
    }
};
