import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { TENANT_ID_TOKEN } from '@portal/core/utils';

export const tenantIdInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const tenantId: string = inject(TENANT_ID_TOKEN);

    if (!tenantId) {
        return next(req);
    }

    return next(
        req.clone({
            // headers: request.headers.append
            setHeaders: { 'tenant-id': tenantId },
        })
    );
};
