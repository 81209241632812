import { DATE_PIPE_DEFAULT_OPTIONS, DatePipeConfig } from '@angular/common';
import { computed, inject, Injectable, Provider, signal, WritableSignal } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
    LuxonDateAdapter,
    MAT_LUXON_DATE_ADAPTER_OPTIONS,
    MatLuxonDateAdapterOptions,
    provideLuxonDateAdapter,
} from '@angular/material-luxon-adapter';
import { DateTime as LuxonDateTime } from 'luxon';

import { DatePipeUtils, LocalizationUtils } from '../utils';

@Injectable()
export class LocalizationSource {
    readonly #dateFormatSource: WritableSignal<LocalizationUtils.DateFormatEnum | null> = signal(null);
    readonly #datePipeFormat = computed(() => DatePipeUtils.getDatePipeFormat(this.#dateFormatSource()));

    readonly #countrySource: WritableSignal<LocalizationUtils.CountryEnum | null> = signal(null);

    public setCountry(country: LocalizationUtils.CountryEnum) {
        this.#countrySource.set(country);
    }

    public getCountry(): LocalizationUtils.CountryEnum | null {
        return this.#countrySource();
    }

    public setDateFormat(value: LocalizationUtils.DateFormatEnum) {
        this.#dateFormatSource.set(value);
    }

    public getDateFormat(): LocalizationUtils.DateFormatEnum | null {
        return this.#dateFormatSource();
    }

    public getDatePipeFormat(): string {
        return this.#datePipeFormat();
    }
}

@Injectable()
export class MyDateAdapter extends LuxonDateAdapter {
    readonly #localizationSource = inject(LocalizationSource);
    readonly #options = inject<MatLuxonDateAdapterOptions>(MAT_LUXON_DATE_ADAPTER_OPTIONS, { optional: true });

    public override format(date: LuxonDateTime, displayFormat: string): string {
        if (!this.isValid(date)) {
            throw Error('LuxonDateAdapter: Cannot format invalid date.');
        }

        const datePipeFormat = this.#localizationSource.getDatePipeFormat();

        if (this.#options?.useUtc) {
            return date.setLocale(this.locale).setZone('utc').toFormat(datePipeFormat);
        } else {
            return date.setLocale(this.locale).toFormat(datePipeFormat);
        }
    }
}

export function provideLocalizationDateAdapter(): Provider[] {
    return [
        LocalizationSource,
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            deps: [LocalizationSource],
            useFactory: (dateOptions: LocalizationSource): DatePipeConfig =>
                Object.create(
                    {},
                    {
                        dateFormat: {
                            get() {
                                return dateOptions.getDatePipeFormat();
                            },
                        },
                    }
                ),
        },

        provideLuxonDateAdapter(),
        {
            provide: DateAdapter,
            useClass: MyDateAdapter,
        },
    ];
}
