import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ContactDto, ContactOrderByEnum, ContactType, Direction, Page } from '../../../dtos';

export interface GetContactsParams {
    searchText?: string;
    page?: number;
    size?: number;
    sort?: ContactOrderByEnum;
    direction?: Direction;
}

export class ContactResource {
    private readonly _api = inject(HttpClient);

    public createContact(contactDto: ContactDto): Observable<ContactDto> {
        return this._api.post<ContactDto>(`contact/v1/contacts`, contactDto);
    }

    public getContacts(params?: GetContactsParams): Observable<any> {
        return this._api.get(`contact/v1/contacts`, { params: { ...params } });
    }

    public getContactsByType(params?: {
        searchText?: string;
        contactType: ContactType;
        page: number;
        size: number;
    }): Observable<Page<ContactDto>> {
        return this._api.get<Page<ContactDto>>(`contact/v1/contacts/contact-type`, { params: { ...params } });
    }

    public getContact(contactId: number): Observable<ContactDto> {
        return this._api.get<ContactDto>(`contact/v1/contacts/${contactId}`);
    }

    public updateContact(contactId: number, contactDto: ContactDto): Observable<ContactDto> {
        return this._api.put<ContactDto>(`contact/v1/contacts/${contactId}`, contactDto);
    }

    public deleteContact(contactId: number): Observable<void> {
        return this._api.delete<void>(`contact/v1/contacts/${contactId}`);
    }

    public addContactGroup(contactId: number, groupId: number): Observable<ContactDto> {
        return this._api.put<ContactDto>(`contact/v1/contacts/${contactId}/group/${groupId}`, null);
    }

    public getContactsByGroup(groupId: number, params?: GetContactsParams): Observable<Page<ContactDto>> {
        return this._api.get<Page<ContactDto>>(`contact/v1/contacts/by-group/${groupId}`, { params: { ...params } });
    }

    public deleteContactGroup(contactId: number, groupId: number): Observable<void> {
        return this._api.delete<void>(`contact/v1/contacts/${contactId}/group/${groupId}`);
    }
}
