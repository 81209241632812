import { LedgerAccountDto } from './ChartOfAccountsDto';

export enum BillingItemUnitMeasure {
    Each = 'Each',
    Minute = 'Minute',
    Procedure = 'Procedure',
    Session = 'Session',
    Page = 'Page',
    Pair = 'Pair',
}
export class BillingItemDto {
    public billingItemId: number | undefined;
    public name: string | undefined;
    public description: string | undefined;
    public type: BillingItemType | undefined;
    public sku: string | undefined;
    public sell: true | undefined;
    public buy: true | undefined;
    public incomeAccountId: number | undefined;
    public expenseAccountId: number | undefined;
    public rateTiers: RateTierDto[] | undefined;
}

export class ExtendedBilingItemDto extends BillingItemDto {
    public incomeAccount?: LedgerAccountDto;
    public expenseAccount?: LedgerAccountDto;
}

export class BillingItemWithEffectiveRate {
    public billingItemId: number | undefined;
    public name: string | undefined;
    public rate: number | undefined;
}

export interface NewCashRateConfig {
    rateTierId: number | undefined | null;
    rate: number | null;
    effectiveDate: string | null;
    quantity: number | null;
    unitMeasure: BillingItemUnitMeasure | null;
    salesTaxCode: string | null;
}

export class RateTierDto {
    public rateTierId: number | undefined | null;
    public rateTierType: RateTierType | undefined | null;
    public rate: number | undefined | null;
    public effectiveDate: string | undefined | null;
    public rateTierHistory: RateTierHistoryDto[] | undefined | null;
    public taxRate: number | undefined | null;
    public code: string | undefined | null;
    public attribute: string | undefined | null;
    public quantity: number | undefined | null;
    public unitMeasure: BillingItemUnitMeasure | undefined | null;
    public salesTaxCode: string | undefined | null;
    public provinceCode: string | undefined | null;

    public static getNewCashRate(config: NewCashRateConfig): RateTierDto {
        return {
            ...config,
            rateTierType: 'CASH',
            taxRate: undefined,
            code: undefined,
            attribute: undefined,
            provinceCode: undefined,
            rateTierHistory: undefined,
        };
    }
}

export class RateTierHistoryDto {
    public rateTierHistoryId: number | undefined;
    public rate: number | undefined;
    public taxRate: number | undefined;
    public effectiveDate: string | undefined;
    public created: string | undefined;
}

export enum BillingItemType {
    Service = 'SERVICE',
    InventoryItem = 'INVENTORY_ITEM',
}

export enum BillingItemFilter {
    Service = 'SERVICE',
    InventoryItem = 'INVENTORY_ITEM',
    All = 'ALL',
}

export type RateTierType = 'CASH' | 'MVA_ON_CAT' | 'MVA_ON_NON_CAT' | 'EHB' | 'WSIB' | 'OHIP';
