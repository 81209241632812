import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { LedgerAccountDto } from '../../../dtos/accounting/dto/ChartOfAccountsDto';

export class ChartOfAccountsResource {
    private readonly api = inject(HttpClient);

    public getLedgerAccount(id: number): Observable<LedgerAccountDto> {
        return this.api.get<LedgerAccountDto>(`accounting/v1/ledger-accounts/${id}`);
    }
}
