import { extensionsTreatedAsImage } from './file-type-constants';
import { FileType } from '../enums/file-type.enum';

export function getFileType(fileType: string | undefined): FileType {
    if (!fileType) {
        return FileType.PDF;
    }

    if (extensionsTreatedAsImage.includes(fileType?.toLowerCase())) {
        return FileType.Image;
    }

    return FileType.PDF;
}
