import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { AuthManager, LocalStorageService, LocalStorageUtil } from '@portal/core/utils';

@Injectable({ providedIn: 'root' })
export class UserAuthService implements AuthManager {
    private static readonly jwtTokenKey: string = 'authUserJwtToken';

    private readonly localeStorageService: LocalStorageService = inject(LocalStorageService);

    private readonly tokenSource: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
        LocalStorageUtil.getItem(UserAuthService.jwtTokenKey)
    );

    constructor() {
        this.tokenSource.pipe(takeUntilDestroyed()).subscribe((token: string | null) => {
            if (token === null) {
                LocalStorageUtil.removeItem(UserAuthService.jwtTokenKey);
            } else {
                LocalStorageUtil.setItem<string | null>(UserAuthService.jwtTokenKey, token);
            }
        });
    }

    public hasToken(): boolean {
        return this.tokenSource.value !== null;
    }

    public getToken(): string | null {
        return this.tokenSource.value;
    }

    public initializeAuthenticationToken(): Observable<string | null> {
        return this.localeStorageService.receive<string | null>(UserAuthService.jwtTokenKey);
    }

    public isAuthenticated(): Observable<boolean> {
        return this.tokenSource.pipe(map((token: string | null) => token !== null));
    }

    public setAuthorizationToken(token: string): void {
        this.tokenSource.next(token);
    }

    public resetAuthorizationToken(): void {
        this.tokenSource.next(null);
    }
}
